@keyframes particle-animation-1 {
  100% {
    transform: translate3d(53vw, 60vh, 46px);
  }
}
.particle:nth-child(1) {
  animation: particle-animation-1 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.64;
  animation-delay: -0.4s;
  background-color: #236576;
  transform: translate3d(23vw, 26vh, 82px);
}

.particlet:nth-child(1) {
  animation: particle-animation-1 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.22;
  animation-delay: -0.4s;
  background-color: #BDD0F5;
  transform: translate3d(5vw, 58vh, 14px);
}

@keyframes particle-animation-2 {
  100% {
    transform: translate3d(9vw, 14vh, 95px);
  }
}
.particle:nth-child(2) {
  animation: particle-animation-2 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.6;
  animation-delay: -0.8s;
  background-color: #236576;
  transform: translate3d(66vw, 16vh, 81px);
}

.particlet:nth-child(2) {
  animation: particle-animation-2 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.34;
  animation-delay: -0.8s;
  background-color: #BDD0F5;
  transform: translate3d(6vw, 27vh, 66px);
}

@keyframes particle-animation-3 {
  100% {
    transform: translate3d(62vw, 11vh, 75px);
  }
}
.particle:nth-child(3) {
  animation: particle-animation-3 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.88;
  animation-delay: -1.2s;
  background-color: #236576;
  transform: translate3d(79vw, 32vh, 69px);
}

.particlet:nth-child(3) {
  animation: particle-animation-3 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.7;
  animation-delay: -1.2s;
  background-color: #BDD0F5;
  transform: translate3d(53vw, 77vh, 49px);
}

@keyframes particle-animation-4 {
  100% {
    transform: translate3d(76vw, 15vh, 29px);
  }
}
.particle:nth-child(4) {
  animation: particle-animation-4 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.5;
  animation-delay: -1.6s;
  background-color: #236576;
  transform: translate3d(6vw, 47vh, 44px);
}

.particlet:nth-child(4) {
  animation: particle-animation-4 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.95;
  animation-delay: -1.6s;
  background-color: #BDD0F5;
  transform: translate3d(70vw, 47vh, 16px);
}

@keyframes particle-animation-5 {
  100% {
    transform: translate3d(23vw, 73vh, 71px);
  }
}
.particle:nth-child(5) {
  animation: particle-animation-5 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.84;
  animation-delay: -2s;
  background-color: #236576;
  transform: translate3d(78vw, 22vh, 99px);
}

.particlet:nth-child(5) {
  animation: particle-animation-5 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.28;
  animation-delay: -2s;
  background-color: #BDD0F5;
  transform: translate3d(6vw, 24vh, 21px);
}

@keyframes particle-animation-6 {
  100% {
    transform: translate3d(89vw, 57vh, 26px);
  }
}
.particle:nth-child(6) {
  animation: particle-animation-6 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.54;
  animation-delay: -2.4s;
  background-color: #236576;
  transform: translate3d(23vw, 24vh, 23px);
}

.particlet:nth-child(6) {
  animation: particle-animation-6 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.28;
  animation-delay: -2.4s;
  background-color: #BDD0F5;
  transform: translate3d(8vw, 84vh, 41px);
}

@keyframes particle-animation-7 {
  100% {
    transform: translate3d(41vw, 63vh, 90px);
  }
}
.particle:nth-child(7) {
  animation: particle-animation-7 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.91;
  animation-delay: -2.8s;
  background-color: #236576;
  transform: translate3d(49vw, 25vh, 85px);
}

.particlet:nth-child(7) {
  animation: particle-animation-7 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.13;
  animation-delay: -2.8s;
  background-color: #BDD0F5;
  transform: translate3d(12vw, 59vh, 99px);
}

@keyframes particle-animation-8 {
  100% {
    transform: translate3d(28vw, 45vh, 1px);
  }
}
.particle:nth-child(8) {
  animation: particle-animation-8 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.19;
  animation-delay: -3.2s;
  background-color: #236576;
  transform: translate3d(25vw, 50vh, 62px);
}

.particlet:nth-child(8) {
  animation: particle-animation-8 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.86;
  animation-delay: -3.2s;
  background-color: #BDD0F5;
  transform: translate3d(43vw, 86vh, 68px);
}

@keyframes particle-animation-9 {
  100% {
    transform: translate3d(65vw, 62vh, 55px);
  }
}
.particle:nth-child(9) {
  animation: particle-animation-9 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.37;
  animation-delay: -3.6s;
  background-color: #236576;
  transform: translate3d(83vw, 18vh, 64px);
}

.particlet:nth-child(9) {
  animation: particle-animation-9 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.62;
  animation-delay: -3.6s;
  background-color: #BDD0F5;
  transform: translate3d(57vw, 45vh, 87px);
}

@keyframes particle-animation-10 {
  100% {
    transform: translate3d(5vw, 23vh, 86px);
  }
}
.particle:nth-child(10) {
  animation: particle-animation-10 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.48;
  animation-delay: -4s;
  background-color: #236576;
  transform: translate3d(21vw, 44vh, 83px);
}

.particlet:nth-child(10) {
  animation: particle-animation-10 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.2;
  animation-delay: -4s;
  background-color: #BDD0F5;
  transform: translate3d(14vw, 7vh, 49px);
}

@keyframes particle-animation-11 {
  100% {
    transform: translate3d(23vw, 83vh, 83px);
  }
}
.particle:nth-child(11) {
  animation: particle-animation-11 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.96;
  animation-delay: -4.4s;
  background-color: #236576;
  transform: translate3d(46vw, 85vh, 26px);
}

.particlet:nth-child(11) {
  animation: particle-animation-11 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.65;
  animation-delay: -4.4s;
  background-color: #BDD0F5;
  transform: translate3d(37vw, 27vh, 49px);
}

@keyframes particle-animation-12 {
  100% {
    transform: translate3d(27vw, 73vh, 71px);
  }
}
.particle:nth-child(12) {
  animation: particle-animation-12 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.46;
  animation-delay: -4.8s;
  background-color: #236576;
  transform: translate3d(68vw, 88vh, 84px);
}

.particlet:nth-child(12) {
  animation: particle-animation-12 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.86;
  animation-delay: -4.8s;
  background-color: #BDD0F5;
  transform: translate3d(56vw, 20vh, 1px);
}

@keyframes particle-animation-13 {
  100% {
    transform: translate3d(67vw, 47vh, 30px);
  }
}
.particle:nth-child(13) {
  animation: particle-animation-13 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.58;
  animation-delay: -5.2s;
  background-color: #236576;
  transform: translate3d(55vw, 55vh, 91px);
}

.particlet:nth-child(13) {
  animation: particle-animation-13 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.98;
  animation-delay: -5.2s;
  background-color: #BDD0F5;
  transform: translate3d(6vw, 44vh, 4px);
}

@keyframes particle-animation-14 {
  100% {
    transform: translate3d(1vw, 28vh, 47px);
  }
}
.particle:nth-child(14) {
  animation: particle-animation-14 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.08;
  animation-delay: -5.6s;
  background-color: #236576;
  transform: translate3d(79vw, 63vh, 56px);
}

.particlet:nth-child(14) {
  animation: particle-animation-14 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.23;
  animation-delay: -5.6s;
  background-color: #BDD0F5;
  transform: translate3d(20vw, 38vh, 61px);
}

@keyframes particle-animation-15 {
  100% {
    transform: translate3d(69vw, 75vh, 97px);
  }
}
.particle:nth-child(15) {
  animation: particle-animation-15 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.5;
  animation-delay: -6s;
  background-color: #236576;
  transform: translate3d(48vw, 14vh, 87px);
}

.particlet:nth-child(15) {
  animation: particle-animation-15 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.81;
  animation-delay: -6s;
  background-color: #BDD0F5;
  transform: translate3d(46vw, 48vh, 59px);
}

@keyframes particle-animation-16 {
  100% {
    transform: translate3d(52vw, 69vh, 41px);
  }
}
.particle:nth-child(16) {
  animation: particle-animation-16 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.48;
  animation-delay: -6.4s;
  background-color: #236576;
  transform: translate3d(1vw, 77vh, 70px);
}

.particlet:nth-child(16) {
  animation: particle-animation-16 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.06;
  animation-delay: -6.4s;
  background-color: #BDD0F5;
  transform: translate3d(53vw, 68vh, 46px);
}

@keyframes particle-animation-17 {
  100% {
    transform: translate3d(64vw, 16vh, 92px);
  }
}
.particle:nth-child(17) {
  animation: particle-animation-17 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 1;
  animation-delay: -6.8s;
  background-color: #236576;
  transform: translate3d(62vw, 71vh, 54px);
}

.particlet:nth-child(17) {
  animation: particle-animation-17 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.73;
  animation-delay: -6.8s;
  background-color: #BDD0F5;
  transform: translate3d(48vw, 69vh, 63px);
}

@keyframes particle-animation-18 {
  100% {
    transform: translate3d(46vw, 56vh, 16px);
  }
}
.particle:nth-child(18) {
  animation: particle-animation-18 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.1;
  animation-delay: -7.2s;
  background-color: #236576;
  transform: translate3d(5vw, 62vh, 81px);
}

.particlet:nth-child(18) {
  animation: particle-animation-18 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.38;
  animation-delay: -7.2s;
  background-color: #BDD0F5;
  transform: translate3d(86vw, 1vh, 85px);
}

@keyframes particle-animation-19 {
  100% {
    transform: translate3d(34vw, 1vh, 25px);
  }
}
.particle:nth-child(19) {
  animation: particle-animation-19 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.28;
  animation-delay: -7.6s;
  background-color: #236576;
  transform: translate3d(34vw, 30vh, 80px);
}

.particlet:nth-child(19) {
  animation: particle-animation-19 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.36;
  animation-delay: -7.6s;
  background-color: #BDD0F5;
  transform: translate3d(67vw, 4vh, 40px);
}

@keyframes particle-animation-20 {
  100% {
    transform: translate3d(58vw, 39vh, 15px);
  }
}
.particle:nth-child(20) {
  animation: particle-animation-20 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.6;
  animation-delay: -8s;
  background-color: #236576;
  transform: translate3d(2vw, 18vh, 15px);
}

.particlet:nth-child(20) {
  animation: particle-animation-20 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.75;
  animation-delay: -8s;
  background-color: #BDD0F5;
  transform: translate3d(76vw, 38vh, 76px);
}

@keyframes particle-animation-21 {
  100% {
    transform: translate3d(50vw, 62vh, 91px);
  }
}
.particle:nth-child(21) {
  animation: particle-animation-21 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.88;
  animation-delay: -8.4s;
  background-color: #236576;
  transform: translate3d(73vw, 50vh, 97px);
}

.particlet:nth-child(21) {
  animation: particle-animation-21 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.59;
  animation-delay: -8.4s;
  background-color: #BDD0F5;
  transform: translate3d(66vw, 71vh, 12px);
}

@keyframes particle-animation-22 {
  100% {
    transform: translate3d(37vw, 11vh, 13px);
  }
}
.particle:nth-child(22) {
  animation: particle-animation-22 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.48;
  animation-delay: -8.8s;
  background-color: #236576;
  transform: translate3d(42vw, 88vh, 32px);
}

.particlet:nth-child(22) {
  animation: particle-animation-22 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.43;
  animation-delay: -8.8s;
  background-color: #BDD0F5;
  transform: translate3d(63vw, 78vh, 68px);
}

@keyframes particle-animation-23 {
  100% {
    transform: translate3d(5vw, 67vh, 58px);
  }
}
.particle:nth-child(23) {
  animation: particle-animation-23 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.44;
  animation-delay: -9.2s;
  background-color: #236576;
  transform: translate3d(22vw, 66vh, 71px);
}

.particlet:nth-child(23) {
  animation: particle-animation-23 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.52;
  animation-delay: -9.2s;
  background-color: #BDD0F5;
  transform: translate3d(11vw, 62vh, 89px);
}

@keyframes particle-animation-24 {
  100% {
    transform: translate3d(50vw, 83vh, 70px);
  }
}
.particle:nth-child(24) {
  animation: particle-animation-24 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 1;
  animation-delay: -9.6s;
  background-color: #236576;
  transform: translate3d(16vw, 80vh, 45px);
}

.particlet:nth-child(24) {
  animation: particle-animation-24 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.72;
  animation-delay: -9.6s;
  background-color: #BDD0F5;
  transform: translate3d(25vw, 19vh, 54px);
}

@keyframes particle-animation-25 {
  100% {
    transform: translate3d(85vw, 31vh, 5px);
  }
}
.particle:nth-child(25) {
  animation: particle-animation-25 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.64;
  animation-delay: -10s;
  background-color: #236576;
  transform: translate3d(70vw, 72vh, 85px);
}

.particlet:nth-child(25) {
  animation: particle-animation-25 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.89;
  animation-delay: -10s;
  background-color: #BDD0F5;
  transform: translate3d(76vw, 13vh, 49px);
}

@keyframes particle-animation-26 {
  100% {
    transform: translate3d(49vw, 7vh, 100px);
  }
}
.particle:nth-child(26) {
  animation: particle-animation-26 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.69;
  animation-delay: -10.4s;
  background-color: #236576;
  transform: translate3d(47vw, 9vh, 90px);
}

.particlet:nth-child(26) {
  animation: particle-animation-26 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.76;
  animation-delay: -10.4s;
  background-color: #BDD0F5;
  transform: translate3d(35vw, 49vh, 33px);
}

@keyframes particle-animation-27 {
  100% {
    transform: translate3d(1vw, 2vh, 57px);
  }
}
.particle:nth-child(27) {
  animation: particle-animation-27 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.7;
  animation-delay: -10.8s;
  background-color: #236576;
  transform: translate3d(10vw, 81vh, 67px);
}

.particlet:nth-child(27) {
  animation: particle-animation-27 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.98;
  animation-delay: -10.8s;
  background-color: #BDD0F5;
  transform: translate3d(76vw, 7vh, 80px);
}

@keyframes particle-animation-28 {
  100% {
    transform: translate3d(5vw, 21vh, 89px);
  }
}
.particle:nth-child(28) {
  animation: particle-animation-28 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.85;
  animation-delay: -11.2s;
  background-color: #236576;
  transform: translate3d(53vw, 76vh, 78px);
}

.particlet:nth-child(28) {
  animation: particle-animation-28 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.86;
  animation-delay: -11.2s;
  background-color: #BDD0F5;
  transform: translate3d(5vw, 21vh, 18px);
}

@keyframes particle-animation-29 {
  100% {
    transform: translate3d(52vw, 88vh, 77px);
  }
}
.particle:nth-child(29) {
  animation: particle-animation-29 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.48;
  animation-delay: -11.6s;
  background-color: #236576;
  transform: translate3d(39vw, 38vh, 4px);
}

.particlet:nth-child(29) {
  animation: particle-animation-29 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.5;
  animation-delay: -11.6s;
  background-color: #BDD0F5;
  transform: translate3d(61vw, 14vh, 83px);
}

@keyframes particle-animation-30 {
  100% {
    transform: translate3d(33vw, 83vh, 32px);
  }
}
.particle:nth-child(30) {
  animation: particle-animation-30 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.09;
  animation-delay: -12s;
  background-color: #236576;
  transform: translate3d(58vw, 23vh, 38px);
}

.particlet:nth-child(30) {
  animation: particle-animation-30 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.11;
  animation-delay: -12s;
  background-color: #BDD0F5;
  transform: translate3d(50vw, 77vh, 1px);
}

@keyframes particle-animation-31 {
  100% {
    transform: translate3d(14vw, 31vh, 75px);
  }
}
.particle:nth-child(31) {
  animation: particle-animation-31 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.31;
  animation-delay: -12.4s;
  background-color: #236576;
  transform: translate3d(24vw, 90vh, 24px);
}

.particlet:nth-child(31) {
  animation: particle-animation-31 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.83;
  animation-delay: -12.4s;
  background-color: #BDD0F5;
  transform: translate3d(41vw, 7vh, 41px);
}

@keyframes particle-animation-32 {
  100% {
    transform: translate3d(47vw, 39vh, 7px);
  }
}
.particle:nth-child(32) {
  animation: particle-animation-32 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.5;
  animation-delay: -12.8s;
  background-color: #236576;
  transform: translate3d(73vw, 73vh, 14px);
}

.particlet:nth-child(32) {
  animation: particle-animation-32 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.11;
  animation-delay: -12.8s;
  background-color: #BDD0F5;
  transform: translate3d(42vw, 72vh, 65px);
}

@keyframes particle-animation-33 {
  100% {
    transform: translate3d(32vw, 15vh, 46px);
  }
}
.particle:nth-child(33) {
  animation: particle-animation-33 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.7;
  animation-delay: -13.2s;
  background-color: #236576;
  transform: translate3d(53vw, 66vh, 51px);
}

.particlet:nth-child(33) {
  animation: particle-animation-33 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.01;
  animation-delay: -13.2s;
  background-color: #BDD0F5;
  transform: translate3d(57vw, 35vh, 2px);
}

@keyframes particle-animation-34 {
  100% {
    transform: translate3d(59vw, 69vh, 15px);
  }
}
.particle:nth-child(34) {
  animation: particle-animation-34 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.49;
  animation-delay: -13.6s;
  background-color: #236576;
  transform: translate3d(41vw, 69vh, 11px);
}

.particlet:nth-child(34) {
  animation: particle-animation-34 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.04;
  animation-delay: -13.6s;
  background-color: #BDD0F5;
  transform: translate3d(28vw, 13vh, 39px);
}

@keyframes particle-animation-35 {
  100% {
    transform: translate3d(37vw, 19vh, 97px);
  }
}
.particle:nth-child(35) {
  animation: particle-animation-35 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.79;
  animation-delay: -14s;
  background-color: #236576;
  transform: translate3d(38vw, 12vh, 23px);
}

.particlet:nth-child(35) {
  animation: particle-animation-35 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.94;
  animation-delay: -14s;
  background-color: #BDD0F5;
  transform: translate3d(25vw, 68vh, 27px);
}

@keyframes particle-animation-36 {
  100% {
    transform: translate3d(37vw, 27vh, 97px);
  }
}
.particle:nth-child(36) {
  animation: particle-animation-36 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.11;
  animation-delay: -14.4s;
  background-color: #236576;
  transform: translate3d(54vw, 80vh, 83px);
}

.particlet:nth-child(36) {
  animation: particle-animation-36 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.79;
  animation-delay: -14.4s;
  background-color: #BDD0F5;
  transform: translate3d(6vw, 13vh, 65px);
}

@keyframes particle-animation-37 {
  100% {
    transform: translate3d(42vw, 60vh, 45px);
  }
}
.particle:nth-child(37) {
  animation: particle-animation-37 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.24;
  animation-delay: -14.8s;
  background-color: #236576;
  transform: translate3d(46vw, 76vh, 21px);
}

.particlet:nth-child(37) {
  animation: particle-animation-37 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.01;
  animation-delay: -14.8s;
  background-color: #BDD0F5;
  transform: translate3d(80vw, 40vh, 46px);
}

@keyframes particle-animation-38 {
  100% {
    transform: translate3d(39vw, 33vh, 100px);
  }
}
.particle:nth-child(38) {
  animation: particle-animation-38 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.49;
  animation-delay: -15.2s;
  background-color: #236576;
  transform: translate3d(15vw, 82vh, 71px);
}

.particlet:nth-child(38) {
  animation: particle-animation-38 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.29;
  animation-delay: -15.2s;
  background-color: #BDD0F5;
  transform: translate3d(34vw, 89vh, 98px);
}

@keyframes particle-animation-39 {
  100% {
    transform: translate3d(81vw, 17vh, 14px);
  }
}
.particle:nth-child(39) {
  animation: particle-animation-39 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.69;
  animation-delay: -15.6s;
  background-color: #236576;
  transform: translate3d(86vw, 62vh, 69px);
}

.particlet:nth-child(39) {
  animation: particle-animation-39 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.89;
  animation-delay: -15.6s;
  background-color: #BDD0F5;
  transform: translate3d(56vw, 4vh, 82px);
}

@keyframes particle-animation-40 {
  100% {
    transform: translate3d(76vw, 84vh, 1px);
  }
}
.particle:nth-child(40) {
  animation: particle-animation-40 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.97;
  animation-delay: -16s;
  background-color: #236576;
  transform: translate3d(63vw, 69vh, 37px);
}

.particlet:nth-child(40) {
  animation: particle-animation-40 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.24;
  animation-delay: -16s;
  background-color: #BDD0F5;
  transform: translate3d(64vw, 18vh, 70px);
}

@keyframes particle-animation-41 {
  100% {
    transform: translate3d(44vw, 11vh, 84px);
  }
}
.particle:nth-child(41) {
  animation: particle-animation-41 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.32;
  animation-delay: -16.4s;
  background-color: #236576;
  transform: translate3d(81vw, 23vh, 32px);
}

.particlet:nth-child(41) {
  animation: particle-animation-41 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.95;
  animation-delay: -16.4s;
  background-color: #BDD0F5;
  transform: translate3d(87vw, 38vh, 53px);
}

@keyframes particle-animation-42 {
  100% {
    transform: translate3d(13vw, 60vh, 68px);
  }
}
.particle:nth-child(42) {
  animation: particle-animation-42 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.51;
  animation-delay: -16.8s;
  background-color: #236576;
  transform: translate3d(44vw, 24vh, 64px);
}

.particlet:nth-child(42) {
  animation: particle-animation-42 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.46;
  animation-delay: -16.8s;
  background-color: #BDD0F5;
  transform: translate3d(67vw, 72vh, 34px);
}

@keyframes particle-animation-43 {
  100% {
    transform: translate3d(56vw, 13vh, 34px);
  }
}
.particle:nth-child(43) {
  animation: particle-animation-43 60s infinite;
  height: 10px;
  width: 10px;
  opacity: 0.8;
  animation-delay: -17.2s;
  background-color: #236576;
  transform: translate3d(9vw, 57vh, 91px);
}

.particlet:nth-child(43) {
  animation: particle-animation-43 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.35;
  animation-delay: -17.2s;
  background-color: #BDD0F5;
  transform: translate3d(51vw, 34vh, 30px);
}

@keyframes particle-animation-44 {
  100% {
    transform: translate3d(72vw, 55vh, 89px);
  }
}
.particle:nth-child(44) {
  animation: particle-animation-44 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.96;
  animation-delay: -17.6s;
  background-color: #236576;
  transform: translate3d(10vw, 39vh, 29px);
}

.particlet:nth-child(44) {
  animation: particle-animation-44 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.19;
  animation-delay: -17.6s;
  background-color: #BDD0F5;
  transform: translate3d(34vw, 78vh, 53px);
}

@keyframes particle-animation-45 {
  100% {
    transform: translate3d(74vw, 41vh, 54px);
  }
}
.particle:nth-child(45) {
  animation: particle-animation-45 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.98;
  animation-delay: -18s;
  background-color: #236576;
  transform: translate3d(39vw, 10vh, 47px);
}

.particlet:nth-child(45) {
  animation: particle-animation-45 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.37;
  animation-delay: -18s;
  background-color: #BDD0F5;
  transform: translate3d(28vw, 29vh, 44px);
}

@keyframes particle-animation-46 {
  100% {
    transform: translate3d(46vw, 79vh, 16px);
  }
}
.particle:nth-child(46) {
  animation: particle-animation-46 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.57;
  animation-delay: -18.4s;
  background-color: #236576;
  transform: translate3d(43vw, 45vh, 70px);
}

.particlet:nth-child(46) {
  animation: particle-animation-46 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.2;
  animation-delay: -18.4s;
  background-color: #BDD0F5;
  transform: translate3d(89vw, 51vh, 48px);
}

@keyframes particle-animation-47 {
  100% {
    transform: translate3d(41vw, 18vh, 18px);
  }
}
.particle:nth-child(47) {
  animation: particle-animation-47 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.92;
  animation-delay: -18.8s;
  background-color: #236576;
  transform: translate3d(40vw, 23vh, 97px);
}

.particlet:nth-child(47) {
  animation: particle-animation-47 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.44;
  animation-delay: -18.8s;
  background-color: #BDD0F5;
  transform: translate3d(86vw, 32vh, 79px);
}

@keyframes particle-animation-48 {
  100% {
    transform: translate3d(1vw, 39vh, 37px);
  }
}
.particle:nth-child(48) {
  animation: particle-animation-48 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.01;
  animation-delay: -19.2s;
  background-color: #236576;
  transform: translate3d(34vw, 34vh, 56px);
}

.particlet:nth-child(48) {
  animation: particle-animation-48 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.94;
  animation-delay: -19.2s;
  background-color: #BDD0F5;
  transform: translate3d(35vw, 13vh, 64px);
}

@keyframes particle-animation-49 {
  100% {
    transform: translate3d(73vw, 54vh, 59px);
  }
}
.particle:nth-child(49) {
  animation: particle-animation-49 60s infinite;
  height: 8px;
  width: 8px;
  opacity: 0.7;
  animation-delay: -19.6s;
  background-color: #236576;
  transform: translate3d(80vw, 13vh, 75px);
}

.particlet:nth-child(49) {
  animation: particle-animation-49 60s infinite;
  height: 9px;
  width: 9px;
  opacity: 0.43;
  animation-delay: -19.6s;
  background-color: #BDD0F5;
  transform: translate3d(14vw, 61vh, 34px);
}

@keyframes particle-animation-50 {
  100% {
    transform: translate3d(57vw, 10vh, 37px);
  }
}
.particle:nth-child(50) {
  animation: particle-animation-50 60s infinite;
  height: 6px;
  width: 6px;
  opacity: 0.23;
  animation-delay: -20s;
  background-color: #236576;
  transform: translate3d(33vw, 72vh, 61px);
}

.particlet:nth-child(50) {
  animation: particle-animation-50 60s infinite;
  height: 7px;
  width: 7px;
  opacity: 0.44;
  animation-delay: -20s;
  background-color: #BDD0F5;
  transform: translate3d(29vw, 41vh, 60px);
}

.particle {
  position: absolute;
  border-radius: 50%;
}


