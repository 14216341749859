.projtitle {
    color: #040a0b; 
    font-family: 'Poppins', sans-serif; 
    font-size: 5rem; 
    font-weight: 300; 
    padding-left: 120px;
    padding-top: 50px;
    z-index: 3; 
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  } 
  
  .proj {
    background-color: white;
    padding-bottom: 100px;
  } 
  
  .texttitle {
    color: #040a0b; 
    font-family: 'Poppins', sans-serif; 
    font-size: 3rem; 
    font-weight: 600; 
    text-shadow: rgba(0, 0, 0, 0.455) 0px 5px 15px;
    
  } 
  
  .projet {
    padding:100px 20px;
    display: grid; 
    justify-content: space-between;
    grid-template-columns: repeat( auto-fit, minmax(400px, 1fr) );
    position: relative;
    max-width: 100vw;
    align-items: center;
    gap: 200px;
  } 
  
  
  
  .mainimg img {
    width: 40vw; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 4;
    
  } 

  .mainimg {
    z-index: 4;
  }
  
  
  
  .mobileimg img {
    width: 20vw; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  } 
  
  .mobileimg {
    position: absolute; 
    left: 35vw; 
    top: 20px;
    z-index: 2;
  }
  
  .textdiv {
    width: 35vw; 
    display: flex; 
    flex-direction: column; 
    gap: 40px; 
    z-index: 3;
    
  } 
  
  .projtext {
    font-family: 'Poppins', sans-serif; 
    color: #6e6e6e; 
    font-size: 1.2rem; 
    font-weight: 600; 
    display: flex; 
    flex-direction: column; 
    gap: 20px;
  } 

  .visit {
    padding: 30px; 
    width: 100px; 
    
    border-radius: 3px;
    color: rgb(255, 255, 255); 
    background-color: #040a0b;
    text-align: center; 
    font-family: 'Poppins', sans-serif; 
    margin-left: 50px;
  } 

  .proj a {
    text-decoration: none;
  }

  .visit:hover {
    background-color: rgb(71, 70, 70); 
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  } 

  .secondimg {
    position: absolute; 
    right: 35vw; 
    top: 20vh;
    z-index: 2;
  } 

  .secondimg img {
    width: 25vw; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }  


  @media screen and (max-width:600px) {
    .parallaxblock {
      top: 20;
      overflow: auto; 
      display: none;
      
    } 
  
    .projtitle {
      padding: 0;
      padding-left: 20px;
      
    }  

    .proj {
      padding-top: 100px;
      
    }

    .mainimg img{
      width: 90vw;
      
    }
  
    .imgdiv {
      width: 90vw; 
      
    } 

    .projet {
      gap: 100px;
      grid-template-columns: 1fr;
      
    }

    .mobileimg img{
      width: 50vw;
      
    } 

    .mobileimg {
      top: 20vh; 
      left: 45vw;
      
    } 

    .textdiv {
      width: 90vw;
      
    } 

    .visit {
      margin: 0;
      float: right;
      
    } 

    .secondimg img {
      width: 70vw; 
      
    } 

    .secondimg {
      top: auto; 
      bottom: 25vh;
      
    }
    
  }