.contactcomp {
    
    background-color: white; 
    display: flex; 
    flex-direction: column; 
    align-items: center;
    padding: 100px 100px; 
    box-shadow: rgba(0, 0, 0, 0.529) 0px 5px 15px;
} 

.contacttitle {
    text-align: center; 
    color: #040a0bab; 
    font-family: 'Poppins', sans-serif; 
    font-size: 2rem; 
    font-weight: 600; 
} 

.contacttext {
    font-family: 'Poppins', sans-serif; 
    color: #afafaf; 
    font-size: 1.2rem; 
    font-weight: 600; 
    display: flex; 
    flex-direction: column; 
    gap: 20px; 
    text-align: center;
} 

.material-symbols-outlined{
    display: flex;
   justify-content: center; 
   font-size: 10rem; 
   color: #afafaf;
} 

.contactbox {
    box-shadow: rgba(0, 0, 0, 0.119) 0px 5px 15px; 
    padding: 100px 50px; 
    width: 60vw;
} 

.contactbox:hover {
    background-color: #236576;
    cursor: pointer;
} 

.formbox {
    display: flex; 
    flex-direction: column; 
    gap: 40px; 
    box-shadow: rgba(0, 0, 0, 0.119) 0px 5px 15px; 
    padding: 100px 50px; 
    width: 60vw; 
    
} 

label {
    text-align: center; 
    color: #040a0bab; 
    font-family: 'Poppins', sans-serif; 
    font-size: 2rem; 
    font-weight: 600; 
} 

.simpleform {
    height: 3rem; 
    background-color: #236576; 
    outline: none; 
    border: none;
} 

.postbutton {
    padding: 20px; 
    width: 120px; 
    
    border-radius: 3px;
    color: rgb(255, 255, 255); 
    background-color: #040a0b;
    
    font-family: 'Poppins', sans-serif; 
    align-self: center;
    
    
} 

.postbutton:hover {
    background-color: rgb(71, 70, 70); 
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
} 

::placeholder {
    color: white; 
    font-family: 'Poppins', sans-serif;
    padding: 10px;
} 

.messagebox {
    height: 7rem; 
    background-color: #236576; 
    outline: none; 
    border: none;
}